import React from 'react'
import { hydrateRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
// import Snap from 'snapsvg-cjs'

import Root from './components/Root'

import 'project-x-ui/dist/main.css'
// import 'react-phone-number-input/style.css'
import './styles.css'

// window.Snap = Snap

hydrateRoot(
  document.querySelector('#app'),
  <BrowserRouter>
      <Root />
  </BrowserRouter>
)
